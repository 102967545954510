export const BLOCK = {
    SIZE: 5,
    MAX_PLACE_DISTANCE: 8,
};
export const TERRIAN = {
    AMPLITUDE: 100,
    INCREMENT_OFFSET: 0.005,
    CHUNK_SIZE: 20,
};
export const CAMERA = {
    MOVING_SPEED: 1,
    JUMP_HEIGHT: 2,
    PERSPECTIVE: {
        FAR: 1000,
        MIDDLE: 500,
        NEAR: 200,
    },
    INITIALIZE: {
        POSITION_Y: 100,
        IS_SHOW_LINESEGMENT: false,
    },
};
export const GRAVITY = 0.1;
export const CONSTANT = {
    INITIAL_AUTO_JUMP: true,
    INITIAL_IS_DISPLAY_LINESEGMENT: false,
};
