class Keyboard {
    constructor(keymaps) {
        this.keys = [];
        this.keymaps = keymaps;
        document.addEventListener('keyup', (e) => this.handleKeyUp(e));
        document.addEventListener('keydown', (e) => this.handleKeyDown(e));
    }
    handleKeyDown(e) {
        this.keys = [...this.keys, e.key];
    }
    handleKeyUp(e) {
        this.keys = this.keys.filter((key) => key !== e.key);
    }
    dispatch() {
        this.keymaps.forEach((keymap) => {
            if (this.keys.includes(keymap.key)) {
                keymap.callback();
            }
        });
    }
}
export { Keyboard };
