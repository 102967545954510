import { isCollideCameraAndBlock } from '@src/utils';
import { BLOCK, CAMERA, GRAVITY } from '@src/constant';
class Character {
    constructor(game, config, terrian) {
        this.ySpeed = 0;
        this.canJump = true;
        this.game = game;
        this.config = config;
        this.terrian = terrian;
        this.keymaps = [
            { key: 'w', callback: this.handleUp.bind(this) },
            { key: 'a', callback: this.handleLeft.bind(this) },
            { key: 's', callback: this.handleDown.bind(this) },
            { key: 'd', callback: this.handleRight.bind(this) },
            { key: ' ', callback: this.handleJump.bind(this) },
        ];
    }
    calcurateGravity() {
        this.game.controls.perspective.position.y = this.game.controls.perspective.position.y - this.ySpeed;
        this.ySpeed = this.ySpeed + GRAVITY;
        this.terrian.getChunkBlocks().forEach((block) => {
            if (isCollideCameraAndBlock(this.game.controls.perspective, block) &&
                this.game.controls.perspective.position.y <= block.position.y + BLOCK.SIZE / 2 &&
                this.game.controls.perspective.position.y >= block.position.y - BLOCK.SIZE / 2) {
                this.game.controls.perspective.position.y = block.position.y + BLOCK.SIZE / 2;
                this.ySpeed = 0;
                this.canJump = true;
            }
        });
    }
    /**
     * handle key event
     */
    handleUp() {
        this.game.controls.moveForward(CAMERA.MOVING_SPEED);
        if (this.config.autoJump)
            return;
        this.terrian.getChunkBlocks().forEach((block) => {
            if (isCollideCameraAndBlock(this.game.controls.perspective, block) && this.game.controls.perspective.position.y <= block.position.y - BLOCK.SIZE / 2) {
                this.game.controls.moveForward(-1 * CAMERA.MOVING_SPEED);
            }
        });
    }
    handleLeft() {
        this.game.controls.moveRight(-1 * CAMERA.MOVING_SPEED);
        if (this.config.autoJump)
            return;
        this.terrian.getChunkBlocks().forEach((block) => {
            if (isCollideCameraAndBlock(this.game.controls.perspective, block) && this.game.controls.perspective.position.y === block.position.y - BLOCK.SIZE / 2) {
                this.game.controls.moveRight(CAMERA.MOVING_SPEED);
            }
        });
    }
    handleDown() {
        this.game.controls.moveForward(-1 * CAMERA.MOVING_SPEED);
        if (this.config.autoJump)
            return;
        this.terrian.getChunkBlocks().forEach((block) => {
            if (isCollideCameraAndBlock(this.game.controls.perspective, block) && this.game.controls.perspective.position.y === block.position.y - BLOCK.SIZE / 2) {
                this.game.controls.moveForward(CAMERA.MOVING_SPEED);
            }
        });
    }
    handleRight() {
        this.game.controls.moveRight(CAMERA.MOVING_SPEED);
        if (this.config.autoJump)
            return;
        this.terrian.getChunkBlocks().forEach((block) => {
            if (isCollideCameraAndBlock(this.game.controls.perspective, block) && this.game.controls.perspective.position.y === block.position.y - BLOCK.SIZE / 2) {
                this.game.controls.moveRight(-1 * CAMERA.MOVING_SPEED);
            }
        });
    }
    handleJump() {
        if (!this.canJump)
            return;
        this.canJump = false;
        this.ySpeed = -1 * CAMERA.JUMP_HEIGHT;
    }
}
export { Character };
