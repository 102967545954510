import { PerspectiveCamera, Raycaster, Vector2 } from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';
import { CAMERA } from '@src/constant';
import { windowSize } from '@src/assets';
class Controller {
    constructor() {
        this.isLock = false;
        this.mouse = new Vector2();
        this.mouse.x = 0.5 * 2 - 1;
        this.mouse.y = -1 * 0.5 * 2 + 1;
        this.perspective = new PerspectiveCamera(75, windowSize.width / windowSize.height, 0.1, CAMERA.PERSPECTIVE.NEAR);
        this.perspective.position.x = 0;
        this.perspective.position.z = 0;
        this.perspective.position.y = CAMERA.INITIALIZE.POSITION_Y;
        this.raycaster = new Raycaster();
        this.pointerLock = new PointerLockControls(this.perspective, document.body);
        document.body.addEventListener('click', this.handleClick.bind(this));
    }
    handleClick() {
        this.isLock = !this.isLock;
        this.isLock ? this.pointerLock.lock() : this.pointerLock.unlock();
    }
    setFar(far) {
        this.perspective.far = far;
        this.perspective.updateProjectionMatrix();
    }
    handleResizeWindow() {
        this.perspective.aspect = windowSize.width / windowSize.height;
        this.perspective.updateProjectionMatrix();
    }
    moveForward(distance) {
        this.pointerLock.moveForward(distance);
    }
    moveRight(distance) {
        this.pointerLock.moveRight(distance);
    }
}
export { Controller };
