import { Mesh, LineSegments, MeshBasicMaterial, BoxGeometry, EdgesGeometry, LineBasicMaterial } from 'three';
import { BLOCK } from '@src/constant';
import { color } from '@src/assets';
const basicMaterial = new MeshBasicMaterial();
const box = new BoxGeometry(BLOCK.SIZE, BLOCK.SIZE, BLOCK.SIZE);
class Block {
    constructor(position, isDisplayable, chunkId) {
        this.texture = [];
        this.isDisplayable = true;
        this.position = position;
        this.isDisplayable = isDisplayable;
        this.chunkId = chunkId;
    }
    display(adjustFacesDirection) {
        const materials = this.texture.map((t) => (adjustFacesDirection.includes(t.name) ? basicMaterial : t.material));
        const blockMesh = this.displayBlock(materials);
        const lineSegment = this.displayLine();
        return { blockMesh, lineSegment };
    }
    displayBlock(materials) {
        const blockMesh = new Mesh(box, materials);
        blockMesh.position.x = this.position.x;
        blockMesh.position.y = this.position.y - BLOCK.SIZE * 2;
        blockMesh.position.z = this.position.z;
        return blockMesh;
    }
    displayLine() {
        const edges = new EdgesGeometry(box);
        const lineSegment = new LineSegments(edges, new LineBasicMaterial({ color: color.black }));
        lineSegment.position.x = this.position.x;
        lineSegment.position.y = this.position.y - BLOCK.SIZE * 2;
        lineSegment.position.z = this.position.z;
        return lineSegment;
    }
}
export { Block };
