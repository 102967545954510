import { Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { Dart, Grass } from '@src/blocks';
import { BLOCK, TERRIAN } from '@src/constant';
class Chunk {
    constructor(noise2D, baseX, baseZ) {
        this.blocks = [];
        this.id = uuidv4();
        this.x1 = baseX;
        this.x2 = baseX + TERRIAN.CHUNK_SIZE;
        this.z1 = baseZ;
        this.z2 = baseZ + TERRIAN.CHUNK_SIZE;
        for (let x = 0; x < TERRIAN.CHUNK_SIZE; x++) {
            for (let z = 0; z < TERRIAN.CHUNK_SIZE; z++) {
                const positionX = baseX + x;
                const positionZ = baseZ + z;
                const xoff = TERRIAN.INCREMENT_OFFSET * positionX;
                const zoff = TERRIAN.INCREMENT_OFFSET * positionZ;
                const y = Math.round((noise2D(xoff, zoff) * TERRIAN.AMPLITUDE) / BLOCK.SIZE);
                this.blocks.push(new Grass(new Vector3(positionX * BLOCK.SIZE, y * BLOCK.SIZE, positionZ * BLOCK.SIZE), true, this.id));
                this.blocks.push(new Dart(new Vector3(positionX * BLOCK.SIZE, (y - 1) * BLOCK.SIZE, positionZ * BLOCK.SIZE), false, this.id));
            }
        }
    }
}
export { Chunk };
