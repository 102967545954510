import { CAMERA, CONSTANT } from '@src/constant';
class Configure {
    constructor() {
        this.autoJump = CONSTANT.INITIAL_AUTO_JUMP;
        this.isShowLineSegment = CAMERA.INITIALIZE.IS_SHOW_LINESEGMENT;
        this.cameraPerspectiveDistance = CAMERA.PERSPECTIVE.NEAR;
    }
    render(params) {
        this.renderToggleAutoJump();
        this.renderChangeCameraDistance(params.handleClickPerspective);
        this.renderLineSegment(params.handleClickLineSegment);
    }
    renderToggleAutoJump() {
        const button = document.getElementById('auto-jump');
        button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
            this.autoJump = !this.autoJump;
            button.innerHTML = `AutoJump: ${this.autoJump ? 'On' : 'Off'}`;
        });
    }
    renderChangeCameraDistance(handleClickPerspective) {
        const button = document.getElementById('camera-perspective');
        button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
            switch (this.cameraPerspectiveDistance) {
                case CAMERA.PERSPECTIVE.NEAR:
                    this.cameraPerspectiveDistance = CAMERA.PERSPECTIVE.MIDDLE;
                    button.innerHTML = `Perspective: Middle`;
                    break;
                case CAMERA.PERSPECTIVE.MIDDLE:
                    this.cameraPerspectiveDistance = CAMERA.PERSPECTIVE.FAR;
                    button.innerHTML = `Perspective: Far`;
                    break;
                case CAMERA.PERSPECTIVE.FAR:
                    this.cameraPerspectiveDistance = CAMERA.PERSPECTIVE.NEAR;
                    button.innerHTML = `Perspective: Near`;
                    break;
            }
            handleClickPerspective(this.cameraPerspectiveDistance);
        });
    }
    renderLineSegment(handleClickLineSegment) {
        const button = document.getElementById('line-segment');
        button === null || button === void 0 ? void 0 : button.addEventListener('click', () => {
            this.isShowLineSegment = !this.isShowLineSegment;
            handleClickLineSegment(this.isShowLineSegment);
            button.innerHTML = `LineSegment: ${this.isShowLineSegment ? 'Show' : 'Hide'}`;
        });
    }
}
export { Configure };
