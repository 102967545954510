import { Scene } from '@src/scene';
import { BLOCK } from '@src/constant';
import { windowSize } from '@src/assets';
import { Renderer } from '@src/renderer';
import { adjustBlockFaces } from '@src/utils';
import { Controller } from '@src/controller';
import { Plane } from '@src/plane';
class Game {
    constructor() {
        this.renderedChunkIds = [];
        this.scene = new Scene();
        this.plane = new Plane();
        this.scene.add(this.plane);
        this.renderer = new Renderer();
        this.controls = new Controller();
        window.addEventListener('resize', this.handleResizeWindow.bind(this));
    }
    loop(beforeUpdate, update, afterUpdate) {
        requestAnimationFrame(this.loop.bind(this, beforeUpdate, update, afterUpdate));
        beforeUpdate();
        update();
        this.render();
        afterUpdate();
    }
    addChunksToScene(chunks, isShowLineSegment) {
        const blocks = chunks.map((chunk) => chunk.blocks).flat();
        chunks
            .filter((chunk) => !this.renderedChunkIds.some((id) => chunk.id === id))
            .map((chunk) => chunk.blocks)
            .flat()
            .forEach((block) => {
            if (!block.isDisplayable)
                return;
            const { blockMesh, lineSegment } = block.display(adjustBlockFaces(block, blocks));
            this.scene.add(blockMesh);
            if (isShowLineSegment)
                this.scene.add(lineSegment);
        });
        this.renderedChunkIds = chunks.map((chunk) => chunk.id);
    }
    addLineSegmentBlock(blocks) {
        blocks.forEach((block) => {
            if (!block.isDisplayable)
                return;
            const { lineSegment } = block.display(adjustBlockFaces(block, blocks));
            this.scene.add(lineSegment);
        });
    }
    removeLineSegmentBlock() {
        this.scene.children.filter((obj) => obj.type === 'LineSegments').forEach((obj) => this.scene.remove(obj));
    }
    render() {
        this.renderer.render(this.scene, this.controls.perspective);
    }
    renderRaycastPlane() {
        this.controls.raycaster.setFromCamera(this.controls.mouse, this.controls.perspective);
        const intersects = this.controls.raycaster.intersectObjects(this.scene.children);
        if (intersects.length === 0) {
            this.plane.visible = false;
            return;
        }
        const intersect = intersects.reduce((accum, intersect) => (accum.distance < intersect.distance ? accum : intersect), intersects[0]);
        if (intersect.distance >= BLOCK.SIZE * BLOCK.MAX_PLACE_DISTANCE) {
            this.plane.visible = false;
            return;
        }
        this.plane.updateCord(intersect);
        this.plane.visible = true;
    }
    handleResizeWindow() {
        this.renderer.setSize(windowSize.width, windowSize.height);
        this.controls.handleResizeWindow();
    }
}
export { Game };
